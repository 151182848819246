type Props = {
  size?: number;
};

export const Haskell = ({ size = 200, ...props }: Props) => {
  return (
    <svg
      {...props}
      style={{
        backgroundColor: '#010101',
        width: size,
        height: size,
      }}
      viewBox="0 0 256 181">
      <g>
        <path
          fill="#717171"
          d="M0,180.6639 L60.2219918,90.3319502 L0,0 L45.1659748,0 L105.387967,
             90.3319502 L45.1659748,180.6639 L0,180.6639 L0,180.6639 Z" />
        <path
          fill="#A3A3A3"
          d="M60.2219918,180.6639 L120.443984,90.3319502 L60.2219918,0 L105.387967,
             0 L225.829875,180.6639 L180.6639,180.6639 L143.026971,124.207469 L105.387967,
             180.6639 L60.2219918,180.6639 L60.2219918,180.6639 Z" />
        <path
          fill="#717171"
          d="M205.757262,127.970954 L185.684647,97.8609957 L255.941909,97.8589212 L255.941909,
             127.970954 L205.757262,127.970954 L205.757262,127.970954 Z" />
        <path
          fill="#717171"
          d="M175.647303,82.8049793 L155.572614,52.6950209 L255.941909,52.6929463 L255.941909,
             82.8049793 L175.647303,82.8049793 L175.647303,82.8049793 Z" />
      </g>
    </svg>
  );
};